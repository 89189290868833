// Icons
import InfoIcon from '../../../public/icons/info.svg';
import CheckIcon from '../../../public/icons/check_circle.svg';

import styles from './FlashMessage.module.scss';

/**
 *
 * @param {Object} props
 * @param {String} props.message
 * @param {'info'|'important'|'alert'|'warning'|'success'|'error'} props.color
 */
function FlashMessage({ message, color }) {
  return (
    <div
      className={`
        ${styles.flash}
        ${color ? styles[color] : styles.primary}
        flex a-center
        flash-message
      `}
    >
      {color === 'success' || color === 'important' ? <CheckIcon /> : <InfoIcon />}
      <div className="m-l-10" dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
}

export default FlashMessage;
